* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: white;
}

body {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  background-color:black;
}





